<template>
  <div class="card-header border-bottom pt-1 pb-1 header_txt">
    <h4 class="card-title">Profile</h4>
  </div>
  <div class="card-body mt-2">
    <vForm @submit="$emit('storeContact', formData)" :validation-schema="schema">
      <div class="row">
        <div class="col-md-6 offset-md-3 col-12">
          <div class="row">

            <div class="col-12">
              <div class="mb-1 row">
                <vField
                    v-model="formData.full_name"
                    type="text"
                    name="full_name"
                    class="form-control add-credit-card-mask"
                    placeholder="Full Name"
                />
                <ErrorMessage name="full_name" class="invalid-feedback d-block qcont"/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="legal-name" class="form-control" v-model="formData.legal_name"
                       placeholder="Legal Name"/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="designation" class="form-control" v-model="formData.designation"
                       placeholder="Designation"/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="company_name" class="form-control" v-model="formData.company_name"
                       placeholder="Company Name"/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="date" id="birthday" class="form-control" v-model="formData.birthday"
                       placeholder="Birthday"/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <select class="form-control" id="gender" v-model="formData.gender">
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="others">Others</option>
                </select>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="location" class="form-control" v-model="formData.location"
                       placeholder="Location"/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="nid" class="form-control" v-model="formData.nid"
                       placeholder="NID"/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="tin_no" class="form-control" v-model="formData.tin_no"
                       placeholder="TIN No."/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="father-name" class="form-control" v-model="formData.father_name"
                       placeholder="Father Name"/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="mother-name" class="form-control" v-model="formData.mother_name"
                       placeholder="Mother Name"/>
              </div>
            </div>

            <div class="col-12 p-0">
              <div class="mb-1 row">
                <v-select
                    placeholder="Select Roles"
                    multiple
                    v-model="formData.company_rules"
                    :options="companyRules"
                    label="name"
                    :reduce="name => name.id"
                />
                <div class="input-group">
                  <ErrorMessage name="company_rules" class="invalid-feedback d-block qcont"/>
                </div>
              </div>
            </div>

            <div class="col-sm-6 offset-sm-3">
              <button type="submit" class="btn btn-primary me-1">Submit</button>
              <button type="reset" class="btn btn-outline-secondary">Reset</button>
            </div>

          </div>
        </div>
      </div>
    </vForm>
  </div>
</template>

<script setup>
import {onMounted, ref, watch, defineProps} from "vue";

const props = defineProps({
  companyRules: {
    type: Array,
    default: []
  },
  formData: {
    type: Object
  }
})

const schema = {
  full_name: 'required'
}

const formData = ref({
  full_name: '',
  legal_name: '',
  designation: '',
  company_name: '',
  department: '',
  birthday: '',
  gender: '',
  location: '',
  nid: '',
  tin_no: '',
  father_name: '',
  mother_name: '',
  company_rules: []
})

watch(() => props.formData, (newFormData) => {
  formDataSet();
});

const formDataSet = () => {
  if (props.formData?.full_name) {
    formData.value = props.formData
    let keys = []
    Object.keys(props.formData.company_roles_data).forEach(key => {
      keys.push(parseInt(key))
    });
    formData.value.company_rules = keys
  }
}
onMounted(() => {
  formDataSet()
})
</script>

<style scoped>

</style>
