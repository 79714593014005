<template>
  <div class="card-header border-bottom pt-1 pb-1 header_txt">
    <h4 class="card-title">Basic Info</h4>
  </div>
  <div class="card-body mt-2">
    <form class="form form-horizontal" @submit.prevent="$emit('storeContact', formData)">

      <div class="row">
        <div class="col-md-6 offset-md-3 col-12">
          <div class="row">

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="full_name" class="form-control" v-model="formData.full_name"
                       placeholder="Full name"/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="legal_name" class="form-control" v-model="formData.legal_name"
                       placeholder="Legal name"/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="location" class="form-control" v-model="formData.location"
                       placeholder="Location"/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="bin_no" class="form-control" v-model="formData.bin_no"
                       placeholder="Bin No."/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="tin_no" class="form-control" v-model="formData.tin_no"
                       placeholder="Tin No."/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="reg.no" class="form-control" v-model="formData.reg_no"
                       placeholder="Registration No."/>
              </div>
            </div>

            <div class="col-12 p-0">
              <div class="mb-1 row">
                <v-select
                    placeholder="Select Roles"
                    multiple
                    v-model="formData.company_rules"
                    :options="companyRules"
                    label="name"
                    :reduce="name => name.id"
                />
                <div class="input-group">
                  <ErrorMessage name="company_rules" class="invalid-feedback d-block qcont"/>
                </div>
              </div>
            </div>

            <div class="col-sm-6 offset-sm-3">
              <button type="submit" class="btn btn-primary me-1">Submit</button>
              <button type="reset" class="btn btn-outline-secondary">Reset</button>
            </div>

          </div>
        </div>
      </div>

    </form>
  </div>
</template>

<script setup>
import {onMounted, ref, watch, defineProps} from "vue";

const props = defineProps({
  companyRules: {
    type: Array,
    default: []
  },
  formData: {
    type: Object
  }
})

const formData = ref({
  full_name: '',
  legal_name: '',
  location: '',
  bin_no: '',
  tin_no: '',
  reg_no: '',
  company_rules: []
})

watch(() => props.formData, (newFormData) => {
  formDataSet();
});

const formDataSet = () => {
  if (props.formData?.full_name) {
    formData.value = props.formData
    let keys = []
    Object.keys(props.formData.company_roles_data).forEach(key => {
      keys.push(parseInt(key))
    });
    formData.value.company_rules = keys
  }
}

onMounted(() => {
  formDataSet()
})
</script>
